import React from 'react';
import Loading from '../resources/images/smallLoader.gif';
export const Spinner = (
  <div
    style={{
      display: 'flex',
      height: '80vh',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw'
    }}
  >
    <img src={Loading} alt="" width="100px" height="100px" />
  </div>
);
