import styled from 'styled-components';
import { animated } from 'react-spring';

export const CollapseWrapper = styled(animated.div)`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 4.5rem;
  z-index: 50;
  left: 0;
  right: 0;
`;
export const ResLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 2rem 2rem;

  & li {
    transition: all 300ms linear 0s;
  }

  & a {
    font-size: 1.4rem;
    line-height: 2;
    color: #4dd2ff;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: grey;
      border-bottom: 1px solid grey;
    }
  }
`;
// text-transform: uppercase;
export const Wrapper = styled.div`
  position: relative;
  padding-top: 0.7rem;
  cursor: pointer;
  display: block;

  & span {
    background: #45546e;
    display: block;
    position: relative;
    width: 3.5rem;
    height: 0.4rem;
    margin-bottom: 0.7rem;
    transition: all ease-in-out 0.2s;
  }

  .open span:nth-child(2) {
    opacity: 0;
  }

  .open span:nth-child(3) {
    transform: rotate(45deg);
    top: -11px;
  }

  .open span:nth-child(1) {
    transform: rotate(-45deg);
    top: 11px;
  }
`;
export const Image = styled.img`
  height: 65%;
  margin: auto 0;

  &:hover {
    cursor: pointer;
  }
`;
export const NavBar = styled(animated.nav)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
  border-bottom: 4px solid rgba(77, 211, 255, 1);

  z-index: 1;
  font-size: 1.4rem;
`;
export const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 2rem;
  justify-content: space-between;
  height: 5rem;
`;
export const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;

  & a {
    color: rgba(0, 37, 51, 1);
    font-size: 1rem;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid rgba(77, 210, 255, 0.2);

    &:hover {
      color: grey;
      border-bottom: 1px solid grey;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;
export const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: 769px) {
    display: none;
  }
`;
