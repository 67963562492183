import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import Navbar from "./Components/Navbar/Navbar";
import { Spinner } from "./_common/Spinner";
import Footer from "./Components/Footer/Footer";
// import Home from './Components/Home/Home';
const Home = lazy(() => import("./Components/Home/Home"));
const AboutUs = lazy(() => import("./Components/AboutUs/AboutUs"));
const Contact = lazy(() => import("./Components/Contact/Contact"));
// Products
const Products = lazy(() => import("./Components/Products/Products"));
const ProByCat = lazy(() => import("./Components/Products/ProductsCat"));
const OnePro = lazy(() => import("./Components/Products/ProductOne"));
// Places
const Places = lazy(() => import("./Components/Places/Places"));
const PlaceCat = lazy(() => import("./Components/Places/PlacesCat"));
// const PlaceOne = lazy(() => import('./Components/Places/PlaceOne'));
// Articles
const Articles = lazy(() => import("./Components/Articles/Articles"));
const NewsByCat = lazy(() => import("./Components/Articles/ArticleCat"));
const OneArt = lazy(() => import("./Components/Articles/OneArticle"));
// Hotels
const Hotels = lazy(() => import("./Components/Hotels/Hotels"));
const HotelsCat = lazy(() => import("./Components/Hotels/HotelsCat"));

const Agencies = lazy(() => import("./Components/Agency/Agencies"));

const Gallery = lazy(() => import("./Components/Gallery/Gallery"));

const App: React.FC = () => {
  const [state, setState] = React.useState({ navbarOpen: false });

  const handleNavbar = () => {
    setState({ navbarOpen: !state.navbarOpen });
  };

  return (
    <>
      <BrowserRouter>
        <Navbar navbarState={state.navbarOpen} handleNavbar={handleNavbar} />
        <Suspense fallback={Spinner}>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/articles" component={Articles} exact />
            <Route path="/products" component={Products} exact />
            <Route path="/products/:catID" component={ProByCat} exact />
            <Route path="/products/one/:id" component={OnePro} exact />
            <Route path="/places" component={Places} exact />
            <Route path="/places/:catID" component={PlaceCat} exact />
            <Route path="/articles/:catID" component={NewsByCat} exact />
            <Route
              key={11}
              path="/articles/one/:id/:catID"
              component={OneArt}
              exact
            />
            <Route
              key={12}
              path="/articles/two/:id/:catID"
              component={OneArt}
              exact
            />
            <Route path="/hotels" component={Hotels} exact />
            <Route path="/hotels/:catID" component={HotelsCat} exact />
            <Route path="/agencies" component={Agencies} exact />
            <Route path="/aboutus" component={AboutUs} exact />
            <Route path="/contact" component={Contact} exact />
            <Route path="/gallery" component={Gallery} exact />
          </Switch>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
