import React from 'react';
import { useSpring, config } from 'react-spring';
import LogoNew from '../../resources/images/NepalNatureFinal1.png';
import {
  Image,
  CollapseWrapper,
  ResLinks,
  Wrapper,
  NavBar,
  FlexContainer,
  NavLinks,
  BurgerWrapper
} from './NavbarStyles';
import { Link, withRouter } from 'react-router-dom';
import './Navbar.scss';

const LogoContainer = ({ onClick }: any) => {
  return <Image onClick={onClick} src={LogoNew} alt="Company Logo" />;
};
const CollapseMenu = (props: any) => {
  const { open } = useSpring({ open: props.navbarState ? 0 : 1 });

  if (props.navbarState === true) {
    return (
      <CollapseWrapper
        style={{
          transform: open
            .interpolate({
              range: [0, 0.2, 0.3, 1],
              output: [0, -20, 0, -200]
            })
            .interpolate(openValue => `translate3d(0, ${openValue}px, 0`)
        }}
      >
        <ResLinks>
          <li>
            <Link to="/" onClick={props.handleNavbar}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/articles" onClick={props.handleNavbar}>
              Articles
            </Link>
          </li>
          <li>
            <Link to="/products" onClick={props.handleNavbar}>
              Products
            </Link>
          </li>
          <li>
            <Link to="/hotels" onClick={props.handleNavbar}>
              Hotels
            </Link>
          </li>
          <li>
            <Link to="/agencies" onClick={props.handleNavbar}>
              Travel Agencies
            </Link>
          </li>
          <li>
            <Link to="/places" onClick={props.handleNavbar}>
              Places
            </Link>
          </li>
          <li>
            <Link to="/gallery" onClick={props.handleNavbar}>
              Gallery
            </Link>
          </li>
          <li>
            <a href="/aboutus" onClick={props.handleNavbar}>
              About Us
            </a>
          </li>
          <li>
            <Link to="/contact" onClick={props.handleNavbar}>
              Contact
            </Link>
          </li>
        </ResLinks>
      </CollapseWrapper>
    );
  }
  return null;
};

const Burgermenu = (props: any) => {
  return (
    <Wrapper onClick={props.handleNavbar}>
      <div className={props.navbarState ? 'open' : ''}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </div>
    </Wrapper>
  );
};

const Navbar = (props: any) => {
  // const barAnimation = useSpring({
  //   from: { transform: 'translate3d(0, -10rem, 0)' },
  //   transform: 'translate3d(0, 0, 0)'
  // });

  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 800,
    config: config.wobbly
  });

  return (
    <>
      <NavBar className="NavBarMain">
        <FlexContainer>
          <LogoContainer onClick={() => props.history.push('/')} />
          <NavLinks className="NavBarLinks" style={linkAnimation}>
            <Link to="/">Home</Link>
            <Link to="/articles">Articles</Link>
            <Link to="/products">Products</Link>
            <Link to="/hotels">Hotels</Link>
            <Link to="/agencies">Travel Agencies</Link>
            <Link to="/places">Places</Link>
            <Link to="/gallery">Gallery</Link>
            <Link to="/aboutus">About Us</Link>
            <Link to="/contact">Contact</Link>
          </NavLinks>
          <BurgerWrapper>
            <Burgermenu
              navbarState={props.navbarOpen}
              handleNavbar={props.handleNavbar}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu
        navbarState={props.navbarState}
        handleNavbar={props.handleNavbar}
      />
    </>
  );
};

export default withRouter(Navbar);
