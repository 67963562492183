import React from "react";
import { Fade } from "react-reveal";

const Footer: React.FC = () => {
  return (
    <>
      <footer className="Footer">
        <div className="FooterContact">
          <div className="FooterMenu">
            <div className="FooterContent">
              <Fade left>
                <div className="FooterText">
                  <div className="FooterTitle">Contact Us:</div>
                  Nepal and Nature Pvt. Ltd. <br />
                  Head office: Bhaisepati, Lalitpur
                  <br />
                  Phone: 9851270111
                  <br /> E-mail: nepalandnature@gmail.com
                  <br />
                  <br />
                </div>
              </Fade>
              <Fade left>
                <div className="FollowUs">
                  <div className="FooterTitle">Follow Us:</div>
                  <div>Facebook</div>

                  <div>Instagram</div>

                  <div>Youtube</div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div className="addon">
          <div>©Copyright: Nepal and Nature Pvt. Ltd.</div>
          <div>
            Developed & maintained by &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://addon.com.np/"
            >
              Addon Engineering
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
